import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const LS_PREFIX = 'deviceManager_';

export interface Device {
	deviceId: string;
	label: string;
}

abstract class AbstractDeviceManager {
	get #localStorageKey() {
		return `${LS_PREFIX}${this.type}_id`;
	}
	get #kind() {
		return `${this.type}input`;
	}

	constructor(private type: 'video' | 'audio') {
		const deviceId = localStorage.getItem(this.#localStorageKey);
		if (deviceId) {
			this.setId(deviceId);
		}

		navigator?.mediaDevices?.addEventListener('devicechange', async () => {
			const availableDevices = await this.loadDevices();

			// Device was unplugged
			if (availableDevices.every(x => x.deviceId !== this.id)) {
				this.setId(null);
			}
		});

		this.loadDevices();
	}

	readonly availableDevices$ = new BehaviorSubject<Device[]>([]);
	get availableDevices() {
		return this.availableDevices$.value;
	}
	get id() {
		return this.id$.value;
	}
	readonly id$ = new BehaviorSubject<string | null>(null);
	readonly devicesAvailable$ = new BehaviorSubject<boolean>(true);

	setId(deviceId: string | null = null) {
		if (this.id !== deviceId) {
			if (deviceId !== null) {
				localStorage.setItem(this.#localStorageKey, deviceId);
			} else {
				localStorage.removeItem(this.#localStorageKey);
			}
			this.id$.next(deviceId);
		}
	}

	async loadDevices() {
		const mediaDevices = await navigator?.mediaDevices?.enumerateDevices();

		const availableDevices: Device[] = [];
		this.devicesAvailable$.next(false);

		mediaDevices
			?.filter(device => device.kind === this.#kind)
			.forEach(device => {
				// If the user doesn't have an active device the list of devices can be sparse,
				// only containing information about weather or not a device of a specific type exists.
				this.devicesAvailable$.next(true);

				const label = device.label
					.replace(/^Default - /, '')
					.replace(/^Communications - /, '');

				// There will be no label on sparse devices
				if (label.length < 1) {
					return;
				}

				if (!availableDevices.some(x => x.label === label)) {
					availableDevices.push({ deviceId: device.deviceId, label: label });
				}
			});

		this.availableDevices$.next(availableDevices);
		return availableDevices;
	}
}

@Injectable({
	providedIn: 'root',
})
export class CameraDeviceManager extends AbstractDeviceManager {
	constructor() {
		super('video');
	}
}

@Injectable({
	providedIn: 'root',
})
export class MicrophoneDeviceManager extends AbstractDeviceManager {
	constructor() {
		super('audio');
	}
}

export interface MappedError {
	name: string;
	cause: string;
	solutionHtml?: string;
	correlationId?: string;
}

export const mapError = (
	error:
		| Error
		| string
		| HttpErrorResponse
		| { error: { name?: string; message?: string } }
): MappedError => {
	if (typeof error === 'string') {
		return {
			name: 'StoreError',
			cause: error,
		};
	}

	if ('name' in error) {
		const cause =
			'To ensure the success of recording, please make sure you allow your camera and microphone. You can adjust your settings once again if you wish to use a different camera or microphone.';
		if (error.name === 'NotFoundError') {
			return {
				name: 'NotFoundError',
				cause,
				solutionHtml:
					'You should enable camera and microphone access in your system settings, and make sure to have at least one camera and one microphone connected.',
			};
		}
		if (error.name === 'NotAllowedError') {
			return {
				name: 'NotAllowedError',
				cause,
				solutionHtml:
					'Click the camera or microphone blocked icon <i class="fal fa-video-slash"></i> in your browser\'s address bar and then refresh.',
			};
		}
		if (error.name === 'NotReadableError') {
			return {
				name: 'NotReadableError',
				cause:
					"It isn't possible to start a media capture with the camera or microphone, probably because another app or tab has reserved it.",
				solutionHtml:
					'You should close all other apps and tabs that have reserved the camera or microphone and then refresh.',
			};
		}
	}

	// Consensus Errors
	if ('error' in error) {
		const { name, message: cause } = error.error;
		if (name || cause) {
			return {
				name,
				cause,
			};
		}
	}

	if (error instanceof HttpErrorResponse) {
		const { cause, correlationId } =
			typeof error.error === 'object'
				? {
						cause: error.error.error ?? error.message,
						correlationId: error.error.correlationId,
				  }
				: {
						cause: error.message,
						correlationId: undefined,
				  };
		return {
			name: 'HttpErrorResponse',
			cause,
			solutionHtml:
				'If this error occurs again, then please contact support and attach the message below:',
			correlationId,
		};
	}

	return {
		name: error['name'],
		cause: error['message'],
		solutionHtml:
			'If this error occurs again, then please contact support and attach the message below:',
	};
};
