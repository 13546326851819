/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	isDevMode,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { FileCacheService } from '@consensus/shared/shared/files/data-access-files';
import { fadeInAnimation } from '@consensus/co/ui-component-animations';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MediaProxy } from '@shared/models';
import Timer = NodeJS.Timer;
import { SessionService } from '@store/scope';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BypassSecurityTrustResourceUrlPipe } from '@shared/pipes';
import { CoSpinnerComponent } from '@consensus/co/ui-progress';
import { NgIf } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { PushPipe } from '@ngrx/component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCirclePlay } from '@fortawesome/pro-duotone-svg-icons';

@Component({
	selector: 'co-encoded-video',
	templateUrl: './encoded-video.component.html',
	styleUrls: ['./encoded-video.component.scss'],
	animations: [fadeInAnimation],
	// For now we will not enable OnPush, but instead just markForCheck whenever appropriate
	// changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgIf,
		CoSpinnerComponent,
		BypassSecurityTrustResourceUrlPipe,
		PushPipe,
		FontAwesomeModule,
	],
})
export class EncodedVideoComponent implements OnInit, OnDestroy {
	readonly #destroyRef = inject(DestroyRef);
	readonly #sessionService = inject(SessionService);
	readonly #changeDetectionRef = inject(ChangeDetectorRef);
	readonly #fileService = inject(FileCacheService);
	@Input() updateInterval = 60;

	@ViewChild('video') videoElement: ElementRef<HTMLVideoElement>;

	admin = false;
	readonly isDevelopmentMode = isDevMode();

	videoUrl: string;
	thumbnailUrl: string;

	loadingVideo = false;
	videoFailed = false;
	videoRetry = 0;

	loadingImage = false;

	#reloadImage = new Subscription();
	#reloadVideo = new Subscription();

	#oldVideoProgress = 0;
	#oldVideoPercentage: number;

	@Input() watchedPercentage = 0;
	@Input() watched = false;

	cheated = false;
	#fullDuration: number;
	#duration: number;
	#playingStart: Date;
	#played = 0;
	#lastUpdate = 0;
	paused = true;
	buffering = false;
	#updateTimer: Timer;
	justManuallyNativeClicked = new BehaviorSubject<boolean>(false);
	justCapacitorHotfixUnpaused = new BehaviorSubject<boolean>(false);
	hasPlayed = false;
	readonly newPlayIcon = faCirclePlay;

	@Input() alt: string;
	@Input() allowSkipping = false;
	@Input() allowDownload = false;
	@Input() autoplay = false;
	@Input() loop = false;
	@Input() muted = false;
	@Input() iconSize = '64px';
	@Input() fluidHeight = false;
	@Input() enableCapacitorHotfix = false;
	@Input() enableCompletenessMessages = true;
	@Input() useNewPlayIcon = false;

	_proxy: MediaProxy;
	@Input() set proxy(proxy: MediaProxy) {
		this._proxy = proxy;
		if (this.videoElement?.nativeElement) {
			proxy.register(this.videoElement.nativeElement);
		}
	}
	get proxy() {
		return this._proxy;
	}

	@Output() completionUpdate = new EventEmitter<number>();
	@Output() completed = new EventEmitter<void>();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() ended = new EventEmitter<void>();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() start = new EventEmitter<void>();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() pause = new EventEmitter<void>();

	#src = '';
	@Input() set src(src: string) {
		if (src === this.#src) {
			return;
		}

		this.#src = src;
		this.videoUrl = null;
		this.videoRetry = 2;
		this.#played = 0;
		this.#lastUpdate = 0;
		this.#oldVideoProgress = 0;
		this.cheated = false;

		setTimeout(() => {
			this.#oldVideoPercentage = this.watchedPercentage;

			if (this.autoplay) {
				this.loadVideo();
			} else {
				this.#loadImage();
			}

			this.#changeDetectionRef.markForCheck();
		}, 0);
	}

	@Input()
	set attachment(id: string) {
		this.src = `attachment/${id}`;
	}

	@Input()
	set driveFileId(id: string) {
		this.src = `materials/${id}`;
	}

	@Input()
	set dashboard(id: string) {
		this.src = `dashboard/${id}`;
	}

	@Input()
	set surveySection(id: string) {
		this.src = `survey-section/${id}`;
	}

	@Input()
	set academyResource([moduleId, resourceId]: [string, string]) {
		this.src = `academy/module/${moduleId}/resource/${resourceId}`;
	}

	@Input()
	set coachingExercise(id: string) {
		this.src = `academy/coaching/exercise/${id}`;
	}

	@Input()
	set coachingInstanceVideo([exerciseId, id]: [string, string]) {
		this.src = `academy/coaching/exercise/${exerciseId}/instance-video/${id}`;
	}

	@Input()
	set coachingFeedback([exerciseId, id]: [string, string]) {
		this.src = `academy/coaching/exercise/${exerciseId}/feedback/${id}`;
	}

	async #loadImage() {
		this.loadingImage = true;
		this.thumbnailUrl = null;

		try {
			this.thumbnailUrl = await this.#fileService.getFileUrl(
				this.#src + '?thumbnail=1'
			);
		} catch (e) {
			console.warn(e);
		} finally {
			this.loadingImage = false;

			if (this.#reloadImage) {
				this.#reloadImage.unsubscribe();
			}
			this.#reloadImage = this.#fileService.onReload(
				this.#src + '?thumbnail=1',
				() => {
					if (this.videoUrl) {
						return;
					}
					this.#loadImage();
				}
			);

			this.#changeDetectionRef.markForCheck();
		}
	}

	async loadVideo() {
		this.loadingVideo = true;
		this.videoFailed = false;
		this.videoUrl = null;

		this.#changeDetectionRef.markForCheck();

		try {
			this.videoUrl = await this.#fileService.getFileUrl(this.#src);
			if (this.proxy) {
				setTimeout(() => {
					if (this.proxy.media != this.videoElement?.nativeElement) {
						this.proxy.register(this.videoElement?.nativeElement);
					}
				});
			}
		} catch (e) {
			console.warn(e);

			this.videoFailed = true;

			if (this.videoRetry > 0) {
				this.videoRetry--;
				console.log('retrying video');
				setTimeout(() => this.loadVideo(), 3000);
			}
		} finally {
			this.loadingVideo = false;

			if (this.#reloadVideo) {
				this.#reloadVideo.unsubscribe();
			}
			this.#reloadVideo = this.#fileService.onReload(this.#src, async () => {
				this.onStop();
				this.#oldVideoProgress =
					this.videoElement?.nativeElement?.currentTime ?? 0;
				await this.loadVideo();
			});

			this.#changeDetectionRef.markForCheck();
		}
	}

	loaded(e) {
		this.#duration = e.target.duration * 0.8;
		this.#fullDuration = e.target.duration;
		this.#playingStart = null;
		this.paused = true;
		this.buffering = false;
		this.pause.emit();

		if (this.#oldVideoPercentage) {
			this.#oldVideoProgress =
				(this.#oldVideoPercentage / 100) * this.#duration;
			this.#played = this.#oldVideoProgress;
			this.#lastUpdate = this.#played;
			this.#oldVideoPercentage = null;
		}

		if (this.videoElement?.nativeElement) {
			this.videoElement.nativeElement.currentTime = this.#oldVideoProgress;

			if (this.autoplay) {
				this.videoElement.nativeElement.play();
			}
		}
		this.#changeDetectionRef.markForCheck();
	}

	onStart() {
		this.#playingStart = new Date();
		this.paused = false;
		this.buffering = false;
		this.start.emit();
		this.#updateTimer = setInterval(
			() => this.updatePercentage(),
			(this.updateInterval + 2) * 1000
		);
		this.#changeDetectionRef.markForCheck();
	}

	onStop() {
		if (!this.#playingStart) {
			return;
		}
		this.#played +=
			(new Date().getTime() - this.#playingStart.getTime()) / 1000 + 1;
		this.#playingStart = null;
		clearInterval(this.#updateTimer);
		this.updatePercentage();
		this.#changeDetectionRef.markForCheck();
	}

	onPause() {
		this.onStop();
		if (this.#duration && this.#played > this.#duration && !this.watched) {
			this.completed.emit();
		}
		setTimeout(() => {
			if (
				Capacitor.isNativePlatform() &&
				this.enableCapacitorHotfix &&
				!this.justManuallyNativeClicked.getValue()
			) {
				this.justCapacitorHotfixUnpaused.next(true);
				this.videoElement.nativeElement.play();
				setTimeout(() => this.justCapacitorHotfixUnpaused.next(false), 1000);
			} else {
				this.paused = true;
				this.pause.emit();
				this.hasPlayed = false;
			}
			this.#changeDetectionRef.markForCheck();
		}, 50);
	}

	videoElementClicked() {
		if (!this.enableCapacitorHotfix) {
			return;
		}
		if (Capacitor.isNativePlatform()) {
			this.justManuallyNativeClicked.next(true);
			setTimeout(() => this.justManuallyNativeClicked.next(false), 3000);
		}
	}

	onPlay() {
		this.hasPlayed = true;
		if (!this.#playingStart) {
			this.onStart();
		}
	}

	onEnd() {
		this.justManuallyNativeClicked.next(true);
		setTimeout(() => this.justManuallyNativeClicked.next(false), 3000);
		this.videoElement.nativeElement.pause();

		this.onStop();

		if (
			this.allowSkipping ||
			(this.#duration && this.#played > this.#duration)
		) {
			if (!this.watched) {
				this.completed.emit();
			}
		} else {
			this.cheated = true;
		}

		this.ended.emit();
		this.#changeDetectionRef.markForCheck();
	}

	onBuffer() {
		this.buffering = true;
		this.onStop();
		this.#changeDetectionRef.markForCheck();
	}

	updatePercentage() {
		if (!this.completionUpdate.observed) {
			return;
		}
		if (!this.#playingStart) {
			return;
		}

		const currentTime =
			this.#played +
			(new Date().getTime() - this.#playingStart.getTime()) / 1000 +
			1;
		const timeElapsed = currentTime - this.#lastUpdate;

		if (timeElapsed >= this.updateInterval) {
			const percentage = (currentTime * 100) / this.#fullDuration;
			this.completionUpdate.emit(percentage);
			this.#lastUpdate = currentTime;
		}
		this.#changeDetectionRef.markForCheck();
	}

	videoClick(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
		if (!this.videoUrl && !this.loadingVideo) {
			this.loadVideo();
			return;
		}
		this.videoElement.nativeElement.play();
	}

	ngOnInit() {
		this.#sessionService.isUserAdmin$
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe(x => {
				this.admin = x;
			});
	}

	onContextMenu() {
		return this.allowDownload;
	}

	ngOnDestroy() {
		clearInterval(this.#updateTimer);
		this.proxy?.deregister(this.videoElement?.nativeElement);
	}
}
